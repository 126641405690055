import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { KanbanPatchTicketFilter } from './kanban.actions';
import { KanbanStateModel, PartialKanbanStateModel } from '../types';

const defaultState = () => {
  return {
    selectType: 'division',
    customsProcedureCondition: '',
    searchCondition: 'outNumberCondition',
  };
};

@State<PartialKanbanStateModel>({
  name: 'kanban',
  defaults: {
    sd: defaultState(),
    dtim: defaultState(),
  },
})
@Injectable()
export class KanbanState {
  @Selector()
  static getStatistics(state: KanbanStateModel) {
    return state.sd;
  }

  @Selector()
  static getDtImport(state: KanbanStateModel) {
    return state.dtim;
  }

  @Action(KanbanPatchTicketFilter)
  kanbanPatchTicketFilter(
    ctx: StateContext<KanbanStateModel>,
    action: KanbanPatchTicketFilter,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      [action.params.type]: {
        ...state[action.params.type],
        ...action.params.value,
      },
    });
  }
}
